@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #ffffff;
  --foreground: #171717;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
  }
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: "Segoe UI", Arial, Helvetica, sans-serif;
}

/* Marquee 애니메이션 */
@keyframes marquee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-200%);
  }
}
